import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

import googleplay from "../images/google-play-button.svg"
import appstore from "../images/app-store-button.svg"

import Layout from "../layouts/layout"
import SEO from "../layouts/seo"

const PricingPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t("navigation.pricing")} />
      <article className="article pricing-page text--center">
        <header className="article-header">
          <h1 className="heading heading--md">{t("pricing-page.header")}</h1>
          <p className="paragraph paragraph--lg">
            <Trans>pricing-page.header-paragraph</Trans>
          </p>
        </header>
        <div class="pricing-content">
          <p>
            <Trans
              i18nKey="pricing-page.paragraph1"
              components={{ strong: <strong /> }}
            />
          </p>
          <h3>
            <Trans>pricing-page.download</Trans>
          </h3>
          <div class="buttons">
            <a href="https://play.google.com/store/apps/details?id=de.tiramizoo.driverapp_trmz">
              <img src={googleplay} alt="google play" />
            </a>
            <a href="https://apps.apple.com/us/app/id1525875219">
              <img src={appstore} alt="app store" />
            </a>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default PricingPage
